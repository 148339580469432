import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { mainCyan, transHover, screen } from "../variables";
import ArrowPlain from "../../images/svg/arrow-plain.svg";

const Wrapper = styled(Link)`
  color: ${mainCyan};
  max-width: max-content;

  .label {
    letter-spacing: 2px;
    position: relative;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1.08rem;
    @media ${screen.small} {
      font-size: 1.1rem;
    }

    &::after {
      content: "";
      height: 2px;
      background: ${mainCyan};
      width: 100%;
      display: block;
      position: absolute;
      bottom: -5px;
      left: 0;
      transform: scaleX(0);
      transition: transform 0.2s ease-in;
      transform-origin: left;
    }
  }

  .arrow {
    svg {
      width: 30px;
      transform: scaleX(-1);
      margin: 0 10px -1px 0;

      .arrow-plain-inner {
        fill: ${mainCyan};
        transition: ${transHover};
      }
    }
  }

  @media ${screen.withCursor} {
    &:hover .label::after {
      transform: scaleX(1);
    }
  }
`;

const ButtonUnderlined = ({ className, to, onClick, label }) => {
  return (
    <Wrapper
      className={className}
      onClick={onClick}
      to={to || "/"}
      role={onClick && "button"}
      aria-label={label || "Go"}
    >
      <span className="arrow">
        <ArrowPlain />
      </span>
      <span className="label">{label || "LABEL"}</span>
    </Wrapper>
  );
};

export default ButtonUnderlined;
