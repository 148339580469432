import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  IconCircleDarkPurpleMd,
  IconCircleLightPurpleMd,
  IconCircleCyanMd,
} from "../components/circles";
import {
  innerWidth,
  mainCyan,
  lightPurple,
  screen,
} from "../components/variables";
import { toSlug, formatDate } from "../helpers";
import listPurpleArrow from "../images/list-purple-arrow.png";
import ButtonUnderlined from "../components/buttons/button-underlined";
import ButtonUnderlinedReversed from "../components/buttons/button-underlined-reversed";

const Wrapper = styled.article`
  overflow: hidden;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media ${screen.xsmall} {
      flex-direction: row;
    }

    .title {
      font-weight: 900;
      line-height: 1.22;
      max-width: 680px;
      font-size: 2rem;
      @media ${screen.xxsmall} {
        font-size: 2.8rem;
        line-height: 1.19;
      }
      @media ${screen.small} {
        font-size: 3rem;
        max-width: none;
      }
    }

    .featured-img {
      width: 100%;
      margin: 26px 0 0 0;
      position: relative;
      @media ${screen.xxsmall} {
        margin: 35px 0 0 0;
      }
      @media ${screen.xsmall} {
        margin: 0 0 0 40px;
      }
      @media ${screen.small} {
        max-width: 800px;
        margin: 0 0 0 70px;
      }

      .icon-circle {
        display: none;
        @media ${screen.small} {
          display: block;
          width: 174px;
          bottom: -87px;
          right: -87px;
        }
      }
    }
  }

  .date-tags {
    font-weight: 700;
    max-width: 1030px;
    margin: 21px auto 0 auto;
    font-size: 1.03rem;
    line-height: 1.4;
    @media ${screen.xxsmall} {
      margin: 38px auto 0 auto;
    }
    @media ${screen.small} {
      font-size: 1.07rem;
    }

    .pipe-symbol {
      color: ${lightPurple};
      margin: 0 10px;
    }
  }

  .content {
    max-width: 1030px;
    margin: 26px auto 0 auto;
    @media ${screen.xxsmall} {
      margin: 38px auto 0 auto;
    }

    .rich-text {
      .block-img {
        margin: 32px 0;

        img {
          display: block;
          max-width: 700px;
          width: 100%;
        }
      }

      div {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        margin: 32px 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }

      p {
        margin: 0 0 21px 0;
        font-size: 1.02rem;
        @media ${screen.small} {
          font-size: 1.06rem;
        }
        &:last-child {
          margin: 0;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 500;
        line-height: 1.5;
        margin: 0 0 26px 0;
        max-width: 900px;
      }

      h1 {
        font-size: 1.9rem;
        @media ${screen.xxsmall} {
          font-size: 2.5rem;
        }
      }
      h2 {
        font-size: 1.7rem;
        @media ${screen.xxsmall} {
          font-size: 2.3rem;
        }
      }
      h3 {
        font-size: 1.5rem;
        @media ${screen.xxsmall} {
          font-size: 2.1rem;
        }
      }
      h4 {
        font-size: 1.35rem;
        @media ${screen.xxsmall} {
          font-size: 1.9rem;
        }
      }

      h5 {
        font-size: 1.3rem;
        @media ${screen.xxsmall} {
          font-size: 1.7rem;
        }
      }
      h6 {
        font-size: 1.25rem;
        @media ${screen.xxsmall} {
          font-size: 1.5rem;
        }
      }

      ol,
      ul {
        padding: 0 0 0 30px;
        margin: 21px 0 31px 0;
        @media ${screen.xxsmall} {
          padding: 0 0 0 40px;
        }
        li {
          margin: 0 0 21px 0;
          line-height: 1.7;
          font-size: 1.02rem;
          @media ${screen.small} {
            font-size: 1.06rem;
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      ol {
        li {
          list-style-type: decimal;
        }
      }

      ul {
        margin: 21px 0 31px 10px;
        li {
          line-height: 1.42;
          position: relative;
          padding: 0 0 0 19px;
          font-size: 1.15rem;

          &::after {
            content: "";
            width: 40px;
            height: 40px;
            background-image: url(${listPurpleArrow});
            background-size: 26px;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            left: -30px;
            top: -7px;
            @media ${screen.xxsmall} {
              left: -30px;
              top: -7px;
            }
          }
        }
      }

      strong {
        font-weight: 700;
      }

      a {
        color: ${mainCyan};
        text-decoration: underline;
        @media ${screen.withCursor} {
          &:hover {
            color: ${lightPurple};
          }
        }
      }
    }

    .quote {
      margin: 32px 0;
      border-left: 6px solid ${lightPurple};
      padding: 2px 0 0 14px;
      max-width: 900px;

      p {
        color: ${lightPurple};
        font-size: 0.94rem;
        letter-spacing: 1px;
        font-weight: 900;
        @media ${screen.xxsmall} {
          font-size: 1.1rem;
        }
      }
    }
  }

  .links {
    display: flex;
    justify-content: space-between;
    margin: 110px 0;

    .prev {
      display: none;
      @media ${screen.xxsmall} {
        display: block;
        visibility: ${(props) => (props.isPreviousBlog ? "visible" : "hidden")};
      }
    }

    .next {
      display: none;
      @media ${screen.xxsmall} {
        display: block;
        visibility: ${(props) => (props.isNextBlog ? "visible" : "hidden")};
      }
    }

    .all {
      display: none;
      @media ${screen.xxsmall} {
        display: block;
      }
    }

    .back {
      display: block;
      @media ${screen.xxsmall} {
        display: none;
      }
    }
  }
`;

const SingleBlog = ({ data }) => {
  const {
    title,
    featured_image,
    edit_published_date,
    title_tag,
    meta_description,
    body,
    categories,
  } = data.content.data;

  const [randomNum, setRandomNum] = useState(0);

  useEffect(() => {
    setRandomNum(Math.floor(Math.random() * 3));
  }, []);

  const circleIcons = [
    <IconCircleDarkPurpleMd className="icon-circle" />,
    <IconCircleLightPurpleMd className="icon-circle" />,
    <IconCircleCyanMd className="icon-circle" />,
  ];

  const { first_publication_date } = data.content;

  const displayDate = edit_published_date
    ? formatDate(edit_published_date)
    : formatDate(first_publication_date);

  const renderContent = body.map((item) => {
    if (item.slice_type === "text") {
      return (
        <div
          key={item.id}
          className="rich-text"
          dangerouslySetInnerHTML={{ __html: item.primary.rich_text.html }}
        />
      );
    }

    if (item.slice_type === "quote") {
      return (
        <div key={item.id} className="quote">
          <p>{item.primary.quote}</p>
        </div>
      );
    }
  });

  // get all article titles ordered by date, to use for next/previous buttons
  const allBlogTitles = data.allBlogs.edges
    .map((item) => {
      return {
        // if edit published date is empty, use the default first publication date from Prismic
        published_date: item.node.data.edit_published_date
          ? Number(item.node.data.edit_published_date.split("-").join(""))
          : Number(
              item.node.first_publication_date.slice(0, 10).split("-").join("")
            ),
        title: item.node.data.title.text,
      };
    })
    // sort by formatted date as a number (e.g 20201225 - 20191225)
    .sort((a, b) => b.published_date - a.published_date)
    // final return only the string title
    .map((item) => item.title);

  // get current blog index in array
  const currentBlogIndex = allBlogTitles.indexOf(title.text);

  // get previous event title next to current blog by -1 index
  const previousBlog = allBlogTitles[currentBlogIndex - 1];

  // get next event title after the current blog by +1 index
  const nextBlog = allBlogTitles[currentBlogIndex + 1];

  // for article schema
  const shareURL = `https://www.k9swim.com.au/blog/${toSlug(title.text)}`;

  const publishedDate = edit_published_date
    ? edit_published_date
    : data.content.first_publication_date;

  return (
    <Layout>
      <SEO
        title={title_tag || title.text}
        description={meta_description}
        image={featured_image.thumbnails.cropped.url}
        url={shareURL}
        // for article schema
        articleInfo={{
          headline: `K9 SWiM! ${title.text}`,
          alternativeHeadline: `${title.text}, read now!`,
          image: featured_image.thumbnails.cropped.url,
          url: shareURL,
          datePublished: publishedDate,
          description: meta_description,
        }}
      />
      <Wrapper isPreviousBlog={previousBlog} isNextBlog={nextBlog}>
        <div className="inner-wrapper">
          <header className="header">
            <h1 className="title">{title.text}</h1>
            <figure className="featured-img">
              <GatsbyImage
                image={featured_image.thumbnails.cropped.gatsbyImageData}
                alt={featured_image.alt || "Featured"}
              />
              {circleIcons[randomNum]}
            </figure>
          </header>

          <div className="date-tags">
            <span>{displayDate}</span>
            {categories.map((item, i) => (
              <span
                key={
                  item.category.document
                    ? item.category.document.data.title.text
                    : i
                }
              >
                <span className="pipe-symbol">|</span>
                {item.category.document &&
                  item.category.document.data.title.text}
              </span>
            ))}
          </div>

          <div className="content">{renderContent}</div>

          <nav className="links">
            <ButtonUnderlinedReversed
              className="prev"
              label="PREVIOUS ARTICLE"
              to={previousBlog && `/blog/${toSlug(previousBlog)}/`}
            />

            <ButtonUnderlined
              className="all"
              label="VIEW ALL"
              to="/blog/"
              noArrow
            />

            <ButtonUnderlined
              className="next"
              label="NEXT ARTICLE"
              to={nextBlog && `/blog/${toSlug(nextBlog)}/`}
            />

            <ButtonUnderlined
              className="back"
              label="BACK TO ALL"
              to="/blog/"
            />
          </nav>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default SingleBlog;

export const data = graphql`
  query ($id: String!) {
    content: prismicBlog(id: { eq: $id }) {
      first_publication_date

      data {
        title {
          text
        }
        categories {
          category {
            document {
              ... on PrismicCategory {
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        edit_published_date
        featured_image {
          alt
          thumbnails {
            cropped {
              url
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        body {
          ... on PrismicBlogDataBodyText {
            id
            slice_type
            primary {
              rich_text {
                html
                text
              }
            }
          }
          ... on PrismicBlogDataBodyQuote {
            id
            slice_type
            primary {
              quote
            }
          }
        }
        title_tag
        meta_description
      }
    }

    allBlogs: allPrismicBlog {
      edges {
        node {
          first_publication_date
          data {
            title {
              text
            }
            edit_published_date
          }
        }
      }
    }
  }
`;
